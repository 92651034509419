import { of, switchMap } from 'rxjs';

import { Contract } from '@app/core/models/contract.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { environment } from '@env/environment';

@Injectable()
export class ContractService {
  private URL: string = environment.apiUrl + 'contracts/';

  constructor(private http: HttpClient) {}

  getContract() {
    return this.http.get(`${this.URL}get-contract`).pipe(
      switchMap((response: any) => {
        const res = response as ResponseModel<Contract>;
        return of(res.data);
      }),
    );
  }

  addContract(content: string) {
    return this.http.post(`${this.URL}add-contract`, { content: content }).pipe(
      switchMap((response: any) => {
        const res = response as ResponseModel<boolean>;
        return of(res.data);
      }),
    );
  }
}
